import React, {useEffect} from "react";
import {Alert, Box, Grid, Typography} from "@mui/material";
import AppButton from "../../components/AppButton";
import {Countries, Days, Gender, Language, Month, Religion, Years} from "../../global/functions";
import AppBackButton from "../../components/AppBackButton";
import AppLogo from "../../components/AppLogo";
import * as yup from "yup";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import RHFSelect from "../../components/HookForm/RHFSelect";
import {FormProvider, RHFTextField} from "../../components/HookForm";
import {CreateProfile, GetLocation} from "../../redux/profile/action";
import {useSelector} from "react-redux";
import RHFLocation from "../../components/HookForm/RHFLocation";
import RHFBirthDate from "../../components/HookForm/RHFBirthDate";
import {Logout} from "../../redux/auth/actions";

const AuthInformationForm = () => {
    const state:any = useSelector(state => state)
    const schema = yup.object().shape({
        username: Yup.string().required(),
        day: Yup.string().required(),
        month: Yup.string().required(),
        year: Yup.string().required(),
        gender: Yup.string().required(),
        nationality: Yup.string(),
        religion: Yup.string(),
        language: Yup.string(),
        country: Yup.string().nullable(),
        city: Yup.string().nullable()
    });
    const methods = useForm({resolver: yupResolver(schema)});

    const {
        handleSubmit,
        formState: {}
    } = methods;
    const location:any = state?.location?.selectedLocation;
    const handlerInformation = (data: any) => {
         CreateProfile(data)
    }

    useEffect(() => {
       GetLocation()
    }, []);

    return (
        <Box className="auth-bg">
            <Grid container sx={{height: "100%"}}>
                <Grid
                    xs={12}
                    sm={2}
                >
                    <Box className="back-button-icon-info">
                        <AppBackButton onClick={()=>{
                            Logout()
                        }}/>
                    </Box>
                </Grid>
                <Grid xs={12}
                      sm={8}
                      md={9}
                      lg={7}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        height: "100%",
                        scale: "0.8",
                    }}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handlerInformation)}
                                      className="sign-form">

                            <Box sx={{textAlign: "center"}}>
                                <Typography variant="h4" className="login-header" sx={{fontSize: "25px"}}>Enter your
                                    information</Typography>


                            </Box>
                            <Box  sx={{margin: "20px 0"}} className="flex justify-center" >
                                <Typography>Your information is stored securely and remains private unless you opt to share it.</Typography>
                            </Box>
                            {
                                state?.error !== null && state?.error.map((item: any) => (
                                    <Alert severity="error" sx={{mb: 2}}>{item}</Alert>
                                ))
                            }
                            <Grid item sx={{marginBottom: "20px"}}>
                                <RHFTextField
                                    attribute={{
                                        name: "username",
                                        readOnly: false,
                                        label: 'User name *',
                                        color: 'gray',
                                        type: 'text',
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{marginBottom: "20px"}}>
                                <RHFBirthDate/>
                            </Grid>
                            <Grid container spacing={2} sx={{marginBottom: "20px"}}>
                                <Grid item xs={12} md={6}>
                                    <RHFSelect
                                        attribute={{
                                            items: Gender,
                                            label: "Gender *",
                                            displayKey: "label",
                                            InputValue: "label",
                                            name: "gender",
                                            color: "gray"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelect
                                        attribute={{
                                            items: Countries,
                                            label: "Nationality",
                                            displayKey: "label",
                                            InputValue: "label",
                                            name: "nationality",
                                            color: "gray"
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{marginBottom: "20px"}}>
                                <Grid item xs={12} md={6}>
                                    <RHFSelect
                                        attribute={{
                                            items: Religion,
                                            label: "Religion",
                                            displayKey: "label",
                                            InputValue: "label",
                                            name: "religion",
                                            color: "gray",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFSelect
                                        attribute={{
                                            items: Language,
                                            label: "Language",
                                            displayKey: "name",
                                            InputValue: "name",
                                            name: "language",
                                            color: "gray"
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{marginBottom: "20px"}}>
                                <Grid item xs={12} md={6}>
                                    <RHFLocation
                                        attribute={{
                                            items: state?.location?.country,
                                            label: "Country",
                                            displayKey: "name",
                                            InputValue: "name",
                                            name: "country",
                                            color: "gray",

                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <RHFLocation
                                        attribute={{
                                            items: state?.location?.cities,
                                            label: "State",
                                            displayKey: "name",
                                            InputValue: "name",
                                            name: "city",
                                            color: "gray",

                                        }}
                                    />
                                </Grid>
                            </Grid>


                            <Box className="done-button" sx={{marginTop: "20px"}}>
                                <AppButton type="submit" value="Next"/>
                            </Box>
                        </FormProvider>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default React.memo(AuthInformationForm)
