// reducer.ts
import {SET_SEARCH} from "../actions";

interface SetInitialized {
    type: string;
    data: any
}

type ActionType = SetInitialized;

const initializedState = null;

const reducer = (state = initializedState, action: ActionType) => {
    const {data} = action;
    switch (action?.type) {
        case SET_SEARCH:
            return data
        default:
            return state;
    }
};

export default reducer;
