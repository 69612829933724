import store from "../store";

export const FETCH_MY_POST_LIST = "FETCH_MY_POST_LIST"
export const FETCH_OTHERS_POST_LIST = "FETCH_OTHERS_POST_LIST"
export const FETCH_EXPLORE_POST_LIST = "FETCH_EXPLORE_POST_LIST"
export const SHOW_POST_LIST = "SHOW_POST_LIST"
export const SET_COMMENT = "SET_COMMENT"
export const LIKE_POST = "LIKE_POST"
export const DELETE_POST = "DELETE_POST"
export const INCREMENT_LIKE_POST = "INCREMENT_LIKE_POST"
export const UPDATE_COMMENT = "UPDATE_COMMENT"
export const UPDATE_COMMENT_REPLY = "UPDATE_COMMENT_REPLY"
export const UPDATE_REPLY_COMMENT = "UPDATE_REPLY_COMMENT"
export const COMMENT_POST = "COMMENT_POST"
export const REPLAY_COMMENT_POST = "REPLAY_COMMENT_POST"
export const TOGGLE_BOOKMARK_POST = "TOGGLE_BOOKMARK_POST"
export const UPLOAD_POST = "UPLOAD_POST"
export const GRID_POST_ROW = "GRID_POST_ROW"
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE"
export const SET_PAGE = "SET_PAGE"


export const FetchMyPostList = (page:any) => {
    store.dispatch({
        type: FETCH_MY_POST_LIST,
        payload:{page}
    })
}

export const FetchOthersPostList = (page:any) => {
    store.dispatch({
        type: FETCH_OTHERS_POST_LIST,
        payload:{page}
    })
}
export const FetchExplorePostList = (page:any) => {
    store.dispatch({
        type: FETCH_EXPLORE_POST_LIST,
        payload:{page}
    })
}


export const LikePost = (type:string , id:any) => {
    store.dispatch({
        type: LIKE_POST,
        payload:{type , id}
    })
}
export const DeletePost = ( id:any) => {
    store.dispatch({
        type: DELETE_POST,
        payload:{ id}
    })
}
export const CommentPost = (id:any , comment:string) => {
    store.dispatch({
        type: COMMENT_POST,
        payload:{id , comment}
    })
}

export const ReplayCommentPost = (id:any , comment:string) => {
    store.dispatch({
        type: REPLAY_COMMENT_POST,
        payload:{id , comment}
    })
}

export const ToggleBookMarkPost = (post:string) => {
    store.dispatch({
        type: TOGGLE_BOOKMARK_POST,
        payload:{post}
    })
}

export const UploadPost = (isPublic:any , type:string , media:any , caption:string) => {
    store.dispatch({
        type: UPLOAD_POST,
        payload:{isPublic , type , media , caption}
    })
}

export const IncrementLikePost = (type:string , id:any) => {
    store.dispatch({
        type: INCREMENT_LIKE_POST,
        payload:{type , id}
    })
}
export const UpdateCurrentPage = (page:any) => {
    store.dispatch({
        type: SET_PAGE,
        page
    })
}
