import initialized from "./global/initialized";
import tags from "./tags/tags";
import tagToggle from "./tags/tag-toggle";
import location from "./profile/location";
import profileToggle from "./profile/profile-toggle";
import posts from "./posts/show-posts";
import comments from "./posts/set-comment";
import error from "./global/error";
import notifications from "./notifications/notification-list";
import suggestedTag from "./tags/suggested-tag";
import bookmarks from "./bookmark/fetch-bookmark-list";
import relationList from "./relation/relation-list";
import pendingList from "./relation/pending-list";
import suggestedList from "./relation/suggested-relation-list";
import chatList from "./chat/chat-list";
import userList from "./relation/user-list";
import userPostList from "./relation/user-post-list";
import currentUser from "./relation/curent-user";
import searchRelation from "./relation/search-relation";
import gridPostRow from "./posts/grid-post-row";
import updatePage from "./posts/update-page";
import search from "./profile/search";
import chat from "./chat/chat";
import currentChat from "./chat/curent-chat";
import publicChat from "./chat/public-chat";
import MessageBox from "./chat/message-box-state";

const reducers: any = {
    initialized,
    error,
    tags,
    chat,
    publicChat,
    currentChat,
    tagToggle,
    suggestedTag,
    location,
    profileToggle,
    posts,
    comments,
    notifications,
    bookmarks,
    search,
    MessageBox,
    relationList,
    chatList,
    pendingList,
    suggestedList,
    userList,
    currentUser,
    userPostList,
    searchRelation,
    gridPostRow,
    updatePage
}

export default reducers;
