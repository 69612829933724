import {SET_PENDING_LIST} from "./action";

interface SetInitialized {
    type: string;
    data: any
}

type ActionType = SetInitialized;

const initializedState =  {
    data:null
};

const reducer = (state = initializedState, action: ActionType) => {
    const { data } = action;
    switch (action?.type) {
        case SET_PENDING_LIST:
            return data;
        default:
            return state;
    }
};

export default reducer;
