import {Box, Grid} from "@mui/material";
import AppTagList from "../../components/AppTagList";
import {MdClose} from "react-icons/md";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

interface AppSelectedTag {
    handlerRemoveSelectTag: (value: any) => void;
}

const AppSelectedTag: React.FC<AppSelectedTag> = ({
                                                      handlerRemoveSelectTag,
                                                  }) => {
    const state: any = useSelector(state => state)

    return (
        <Grid container spacing={2}>
            {state.tags && state.tags.length > 0 && state.tags?.map((item: any) => (
               <Box sx={{display:"flex"}}>
                   <AppTagList
                       key={item?.id}
                       label={item?.title}
                       item={item}
                       onClick={handlerRemoveSelectTag}
                       icon={<MdClose/>}
                       className="selected-tag"
                   />
               </Box>
            ))}
        </Grid>
    );
};

export default React.memo(AppSelectedTag);
