import React, {useCallback} from "react";
import { Button } from "@mui/material";
import Google from "../../assets/images/google.svg";
import ApiRouts from "../../boot/api-routes";

const AppButtonGmail = () => {
    const loginWithEmail = useCallback(()=>{
        window.location.replace(process.env.REACT_APP_BASE_URL + ApiRouts?.SIGN_IN_WITH_GOOGLE)
    },[])
    return (
        <Button onClick={loginWithEmail}
                sx={{
                    color:"#213555",
                    borderRadius:"26px",
                    padding:"11px",
                    fontSize:"14px",
                    border:"1px solid #213555",
                    textTransform:"none"
                }}
                fullWidth={true}
                variant="outlined">
            <img src={Google} alt="google" style={{marginRight:"8px"}}/> Sign in with Google
        </Button>
    );
};
export default React.memo(AppButtonGmail);
