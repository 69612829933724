export const GetMessagesError = (error: any) => {
    const errorMessages = error?.response?.data?.errors;
    if (errorMessages) {
        const arrayMessages = Object.entries(errorMessages);
        const messages = arrayMessages?.map((item) => item[1])?.flat(2);
        return messages;
    } else if(error?.response?.data?.message) {
        const errorMessages = error?.response?.data?.message;
        return [errorMessages];
    }
};
