import {SET_PAGE, SHOW_POST_LIST, UPDATE_CURRENT_PAGE} from "./action";

interface SetInitialized {
    type: string;
    page: any
}

type ActionType = SetInitialized;

const initialState = {
    currentPage: 1,
};

function reducer(state = initialState, action:ActionType) {
    switch (action.type) {
        case SHOW_POST_LIST:
            return state;
        case UPDATE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1,
            };
        case SET_PAGE:
            return {
                ...state,
                currentPage: action?.page,
            };
        default:
            return state;
    }
}

export default reducer;
