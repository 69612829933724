import { all } from "redux-saga/effects";
import auth from "./auth/saga";
import profile from "./profile/saga";
import tag from "./tags/saga";
import posts from "./posts/saga";
import notifications from "./notifications/saga";
import bookmark from "./bookmark/saga";
import relation from "./relation/saga";
import chats from "./chat/saga";
import search from "./profile/search";

export default function* rootSaga() {
    yield all([
        auth(),
        profile(),
        tag(),
        posts(),
        notifications(),
        bookmark(),
        relation(),
        chats()
    ]);
}
