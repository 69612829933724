import {TOGGLE_PAGE} from "../tags/action";

interface ToggleInterface {
    type: string;
}

type ActionType = ToggleInterface;

const initialized = () => {
    return true
};
const reducer = (state: any = initialized(), action: ActionType) => {

    switch (action?.type) {
        case TOGGLE_PAGE:
            return !state
        default:
            return state;
    }
};

export default reducer;
