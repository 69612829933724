export const INITIALIZE = "INITIALIZE";
export const ERROR = "ERROR";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_WITH_GOOGLE = "SIGN_IN_WITH_GOOGLE";
export const SIGN_IN_WITH_FACEBOOK = "SIGN_IN_WITH_FACEBOOK";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const PROFILE = "PROFILE";
export const SIGN_UP = "SIGN_UP";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGOUT = "LOGOUT";
export const GET_COUNTRY  = "GET_COUNTRY";
export const GET_CITY  = "GET_CITY";
export const GET_LOCATION = "GET_LOCATION"
export const FETCH_TAG_LIST = "FETCH_TAG_LIST";
export const FETCH_NOTIFICATION_LIST  = "FETCH_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST  = "GET_NOTIFICATION_LIST";
export const CLOSE_NOTIFICATION  = "CLOSE_NOTIFICATION";
export const SUGGESTED_TAG  = "SUGGESTED_TAG";
export const RELATION_LIST  = "RELATION_LIST";
export const SET_RELATION_LIST  = "SET_RELATION_LIST";
export const CHAT_LIST  = "CHAT_LIST";
export const SET_CHAT_LIST  = "SET_CHAT_LIST";
export const SET_PUBLIC_CHAT  = "SET_PUBLIC_CHAT";
export const SEARCH  = "SEARCH";
export const SET_SEARCH  = "SET_SEARCH";
