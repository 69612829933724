import {
    SIGN_IN,
    SIGN_UP,
    FORGET_PASSWORD,
    RESET_PASSWORD,
    SIGN_IN_WITH_GOOGLE,
    PROFILE,
    SIGN_IN_WITH_FACEBOOK,
    VERIFY_EMAIL,
    LOGOUT,
    RESEND_EMAIL
} from "../actions";
import store from "../store";

export const SignIn = (email: any, password: any ) => {
    store.dispatch({
        type: SIGN_IN,
        payload: {email, password },
    })
}
export const SignInWithGoogle = (params: any) => {
    store.dispatch({
        type: SIGN_IN_WITH_GOOGLE,
        payload: {params },
    })
};

export const SignInWithFacebook = (params: any) => {
    store.dispatch({
        type: SIGN_IN_WITH_FACEBOOK,
        payload: {params},
    })
};

export const VerifyEmail = (params: any ) => {
    store.dispatch({
        type: VERIFY_EMAIL,
        payload: {params },
    })
}

export const ResendEmail = () => {
    store.dispatch({
        type: RESEND_EMAIL,
    })
}

export const Profile = (data: any) => ({
    type: PROFILE,
    payload: data,
});

export const SignUp = (email: any, password: any, password_confirmation: any) => {
    store.dispatch({
        type: SIGN_UP,
        payload: {email, password, password_confirmation},
    })
}

export const ForgetPassword = (email: any) => {
    store.dispatch({
        type: FORGET_PASSWORD,
        payload: {email}
    })
}

export const ResetPassword = (password: any, password_confirmation: any, email: any, token: any ) => {
    store.dispatch({
        type: RESET_PASSWORD,
        payload: {password, password_confirmation, email, token }
    })
}

export const Logout = () => {
    store.dispatch({
        type: LOGOUT,
    })
}