import {GET_NOTIFICATION_LIST} from "../actions";
import {SET_MESSAGE} from "../chat/action";
import {SET_NOTIFICATION} from "./action";

interface SetInitialized {
    type: string;
    data: any,
    notification?:any,
}

type ActionType = SetInitialized;

const initializedState = null

const reducer = (state:any = initializedState, action: ActionType) => {
    const { data } = action;
    switch (action?.type) {
        case GET_NOTIFICATION_LIST:
            return data;
        case SET_NOTIFICATION:
            let notification = action?.notification;
            return [
                notification,
                ...state
            ];
        default:
            return state;
    }
};

export default reducer;
