import {ERROR} from "../actions";

interface SetError {
    type: string;
    data: any
}

type ActionType = SetError;

const errorState = () => {
    return null
};

const reducer = (state = errorState(), action: ActionType) => {
    const { data } = action;
    switch (action?.type) {
        case ERROR:
            return data;
        default:
            return state;
    }
};

export default reducer;
