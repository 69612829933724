import axios from "axios";
import { GetMessagesError } from "./error-message";
import store from "../redux/store";
import { ERROR } from "../redux/actions";
import Loader from "../components/Loader/Loader";



const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
    (request) => {
        const token = window.localStorage.getItem("token");
        if (token != null && token) {
            request.headers.Authorization = token;
        }
        // showLoader();
        return request;
    },
    (error) => {
        // hideLoader();
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // hideLoader();
        return response;
    },
    (error) => {
        // hideLoader();
        // Handle error and show messages
        const messages = GetMessagesError(error);

        if (messages?.length) {
            store.dispatch({
                type: ERROR,
                data: messages
            })
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
