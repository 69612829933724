import {INCREMENT_LIKE_POST, SHOW_POST_LIST, UPDATE_COMMENT, UPDATE_COMMENT_REPLY} from "./action";
import {SET_USER_POST_LIST} from "../relation/action";
import {SET_BOOKMARK_LIST} from "../bookmark/action";

interface SetInitialized {
    type: string;
    data: any;
    links?: object,
    id?: any,
    parent_id?: any,
    comment?: any,
}

type ActionType = SetInitialized;

const initializedState = () => {
    return []
};

const reducer = (state = initializedState(), action: ActionType) => {
    const {data, links} = action;
    switch (action?.type) {
        case SHOW_POST_LIST:
        case SET_BOOKMARK_LIST:
        case SET_USER_POST_LIST:
            return {data, links};
        case INCREMENT_LIKE_POST:
            const index = data.findIndex((item: any) => {
                return item.id == action.id
            })
            if (data[index].has_liked) {
                data[index].stats.like -= 1;
                data[index].has_liked = false;
            } else {
                data[index].stats.like += 1;
                data[index].has_liked = true;

            }
            return {data, links}
        case UPDATE_COMMENT:
            const postIndex = data.findIndex((item: any) => {
                return item.id == action.id
            })
            data[postIndex].comments.push(action.comment);
            data[postIndex].stats.comment += 1;
            return {data, links}
        case UPDATE_COMMENT_REPLY:
            const post = data.findIndex((item: any) => {
                return item.id == action.parent_id
            })
            const commentIndex = data[post].comments.findIndex((item: any) => {
                return item.id == action.id
            });
            data[post].comments[commentIndex].comments.push(action.comment);
               data[post].stats.comment += 1;
            return {data, links}
        default:
            return state;
    }
};

export default reducer;
