import {SET_BOOKMARK_LIST} from "./action";

interface SetInitialized {
    type: string;
    data: any
}

type ActionType = SetInitialized;

const initializedState = () => {
    return []
};

const reducer = (state:any = initializedState(), action: ActionType) => {
    const { data } = action;
    switch (action?.type) {
        case SET_BOOKMARK_LIST:
            return data;
        default:
            return state;
    }
};

export default reducer;
