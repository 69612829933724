import React, {useState} from "react";
import SelectTag from "./select-tag";
import EnterTag from "./enter-tag";
import {useSelector} from "react-redux";

export default function Tags() {
    const state: any = useSelector(state => state)

    return state.tagToggle ? <SelectTag/> : <EnterTag/>;
}
