import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {toast} from "react-toastify";
export const checkImageURL = (url:any) => {
    if (!url) return false
    else {
        const pattern = new RegExp('^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$', 'i');
        return pattern.test(url);
    }
};
export function setToken(value = "") {
    const token = `Bearer ${value}`;
    window.localStorage.setItem("token", token);
    return token;
}

export const Days = [
    {id: "01", label: "01"},
    {id: "02", label: "02"},
    {id: "03", label: "03"},
    {id: "04", label: "04"},
    {id: "05", label: "05"},
    {id: "06", label: "06"},
    {id: "07", label: "07"},
    {id: "08", label: "08"},
    {id: "09", label: "09"},
    {id: "10", label: "10"},
    {id: "11", label: "11"},
    {id: "12", label: "12"},
    {id: "13", label: "13"},
    {id: "14", label: "14"},
    {id: "15", label: "15"},
    {id: "16", label: "16"},
    {id: "17", label: "17"},
    {id: "18", label: "18"},
    {id: "19", label: "19"},
    {id: "20", label: "20"},
    {id: "21", label: "21"},
    {id: "22", label: "22"},
    {id: "23", label: "23"},
    {id: "24", label: "24"},
    {id: "25", label: "25"},
    {id: "26", label: "26"},
    {id: "27", label: "27"},
    {id: "28", label: "28"},
    {id: "29", label: "29"},
    {id: "30", label: "30"},
    {id: "31", label: "31"},
]

export const Month = [
    {label: "January", value: "01"},
    {label: "February", value: "02"},
    {label: "March", value: "03"},
    {label: "April", value: "04"},
    {label: "May", value: "05"},
    {label: "June", value: "06"},
    {label: "July", value: "07"},
    {label: "August", value: "08"},
    {label: "September", value: "09"},
    {label: "October", value: "10"},
    {label: "November", value: "11"},
    {label: "December", value: "12"},
]

export const Years = [
    {id: "1", label: "1960"},
    {id: "2", label: "1961"},
    {id: "3", label: "1962"},
    {id: "4", label: "1963"},
    {id: "5", label: "1964"},
    {id: "6", label: "1965"},
    {id: "7", label: "1966"},
    {id: "8", label: "1967"},
    {id: "9", label: "1968"},
    {id: "10", label: "1969"},
    {id: "11", label: "1970"},
    {id: "12", label: "1971"},
    {id: "13", label: "1972"},
    {id: "14", label: "1973"},
    {id: "15", label: "1974"},
    {id: "16", label: "1975"},
    {id: "17", label: "1976"},
    {id: "18", label: "1977"},
    {id: "19", label: "1978"},
    {id: "20", label: "1979"},
    {id: "21", label: "1980"},
    {id: "22", label: "1981"},
    {id: "23", label: "1982"},
    {id: "24", label: "1983"},
    {id: "25", label: "1984"},
    {id: "26", label: "1985"},
    {id: "27", label: "1986"},
    {id: "28", label: "1987"},
    {id: "29", label: "1988"},
    {id: "30", label: "1989"},
    {id: "31", label: "1990"},
    {id: "32", label: "1991"},
    {id: "33", label: "1992"},
    {id: "34", label: "1993"},
    {id: "35", label: "1994"},
    {id: "36", label: "1995"},
    {id: "37", label: "1996"},
    {id: "38", label: "1997"},
    {id: "39", label: "1998"},
    {id: "40", label: "1999"},
    {id: "41", label: "2000"},
    {id: "42", label: "2001"},
    {id: "43", label: "2002"},
    {id: "44", label: "2003"},
    {id: "45", label: "2004"},
    {id: "46", label: "2005"},
    {id: "47", label: "2006"},
    {id: "48", label: "2007"},
    {id: "49", label: "2008"},
    {id: "50", label: "2009"},
    {id: "51", label: "2010"},
    {id: "52", label: "2011"},
    {id: "53", label: "2012"},
    {id: "54", label: "2013"},
    {id: "55", label: "2014"},
    {id: "56", label: "2015"},
    {id: "57", label: "2016"},
    {id: "58", label: "2017"},
    {id: "59", label: "2018"},
    {id: "60", label: "2019"},
    {id: "61", label: "2020"},
    {id: "62", label: "2021"},
    {id: "63", label: "2022"},
    {id: "64", label: "2023"},
]

export const Language = [
    {"code": "aa", "name": "Afar"},
    {"code": "ab", "name": "Abkhazian"},
    {"code": "ae", "name": "Avestan"},
    {"code": "af", "name": "Afrikaans"},
    {"code": "ak", "name": "Akan"},
    {"code": "am", "name": "Amharic"},
    {"code": "an", "name": "Aragonese"},
    {"code": "ar", "name": "Arabic"},
    {"code": "as", "name": "Assamese"},
    {"code": "av", "name": "Avaric"},
    {"code": "ay", "name": "Aymara"},
    {"code": "az", "name": "Azerbaijani"},
    {"code": "ba", "name": "Bashkir"},
    {"code": "be", "name": "Belarusian"},
    {"code": "bg", "name": "Bulgarian"},
    {"code": "bh", "name": "Bihari languages"},
    {"code": "bi", "name": "Bislama"},
    {"code": "bm", "name": "Bambara"},
    {"code": "bn", "name": "Bengali"},
    {"code": "bo", "name": "Tibetan"},
    {"code": "br", "name": "Breton"},
    {"code": "bs", "name": "Bosnian"},
    {"code": "ca", "name": "Catalan; Valencian"},
    {"code": "ce", "name": "Chechen"},
    {"code": "ch", "name": "Chamorro"},
    {"code": "co", "name": "Corsican"},
    {"code": "cr", "name": "Cree"},
    {"code": "cs", "name": "Czech"},
    {
        "code": "cu",
        "name": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
    },
    {"code": "cv", "name": "Chuvash"},
    {"code": "cy", "name": "Welsh"},
    {"code": "da", "name": "Danish"},
    {"code": "de", "name": "German"},
    {"code": "dv", "name": "Divehi; Dhivehi; Maldivian"},
    {"code": "dz", "name": "Dzongkha"},
    {"code": "ee", "name": "Ewe"},
    {"code": "el", "name": "Greek, Modern (1453-)"},
    {"code": "en", "name": "English"},
    {"code": "eo", "name": "Esperanto"},
    {"code": "es", "name": "Spanish; Castilian"},
    {"code": "et", "name": "Estonian"},
    {"code": "eu", "name": "Basque"},
    {"code": "fa", "name": "Persian"},
    {"code": "ff", "name": "Fulah"},
    {"code": "fi", "name": "Finnish"},
    {"code": "fj", "name": "Fijian"},
    {"code": "fo", "name": "Faroese"},
    {"code": "fr", "name": "French"},
    {"code": "fy", "name": "Western Frisian"},
    {"code": "ga", "name": "Irish"},
    {"code": "gd", "name": "Gaelic; Scomttish Gaelic"},
    {"code": "gl", "name": "Galician"},
    {"code": "gn", "name": "Guarani"},
    {"code": "gu", "name": "Gujarati"},
    {"code": "gv", "name": "Manx"},
    {"code": "ha", "name": "Hausa"},
    {"code": "he", "name": "Hebrew"},
    {"code": "hi", "name": "Hindi"},
    {"code": "ho", "name": "Hiri Motu"},
    {"code": "hr", "name": "Croatian"},
    {"code": "ht", "name": "Haitian; Haitian Creole"},
    {"code": "hu", "name": "Hungarian"},
    {"code": "hy", "name": "Armenian"},
    {"code": "hz", "name": "Herero"},
    {
        "code": "ia",
        "name": "Interlingua (International Auxiliary Language Association)"
    },
    {"code": "id", "name": "Indonesian"},
    {"code": "ie", "name": "Interlingue; Occidental"},
    {"code": "ig", "name": "Igbo"},
    {"code": "ii", "name": "Sichuan Yi; Nuosu"},
    {"code": "ik", "name": "Inupiaq"},
    {"code": "io", "name": "Ido"},
    {"code": "is", "name": "Icelandic"},
    {"code": "it", "name": "Italian"},
    {"code": "iu", "name": "Inuktitut"},
    {"code": "ja", "name": "Japanese"},
    {"code": "jv", "name": "Javanese"},
    {"code": "ka", "name": "Georgian"},
    {"code": "kg", "name": "Kongo"},
    {"code": "ki", "name": "Kikuyu; Gikuyu"},
    {"code": "kj", "name": "Kuanyama; Kwanyama"},
    {"code": "kk", "name": "Kazakh"},
    {"code": "kl", "name": "Kalaallisut; Greenlandic"},
    {"code": "km", "name": "Central Khmer"},
    {"code": "kn", "name": "Kannada"},
    {"code": "ko", "name": "Korean"},
    {"code": "kr", "name": "Kanuri"},
    {"code": "ks", "name": "Kashmiri"},
    {"code": "ku", "name": "Kurdish"},
    {"code": "kv", "name": "Komi"},
    {"code": "kw", "name": "Cornish"},
    {"code": "ky", "name": "Kirghiz; Kyrgyz"},
    {"code": "la", "name": "Latin"},
    {"code": "lb", "name": "Luxembourgish; Letzeburgesch"},
    {"code": "lg", "name": "Ganda"},
    {"code": "li", "name": "Limburgan; Limburger; Limburgish"},
    {"code": "ln", "name": "Lingala"},
    {"code": "lo", "name": "Lao"},
    {"code": "lt", "name": "Lithuanian"},
    {"code": "lu", "name": "Luba-Katanga"},
    {"code": "lv", "name": "Latvian"},
    {"code": "mg", "name": "Malagasy"},
    {"code": "mh", "name": "Marshallese"},
    {"code": "mi", "name": "Maori"},
    {"code": "mk", "name": "Macedonian"},
    {"code": "ml", "name": "Malayalam"},
    {"code": "mn", "name": "Mongolian"},
    {"code": "mr", "name": "Marathi"},
    {"code": "ms", "name": "Malay"},
    {"code": "mt", "name": "Maltese"},
    {"code": "my", "name": "Burmese"},
    {"code": "na", "name": "Nauru"},
    {
        "code": "nb",
        "name": "Bokmål, Norwegian; Norwegian Bokmål"
    },
    {"code": "nd", "name": "Ndebele, North; North Ndebele"},
    {"code": "ne", "name": "Nepali"},
    {"code": "ng", "name": "Ndonga"},
    {"code": "nl", "name": "Dutch; Flemish"},
    {"code": "nn", "name": "Norwegian Nynorsk; Nynorsk, Norwegian"},
    {"code": "no", "name": "Norwegian"},
    {"code": "nr", "name": "Ndebele, South; South Ndebele"},
    {"code": "nv", "name": "Navajo; Navaho"},
    {"code": "ny", "name": "Chichewa; Chewa; Nyanja"},
    {"code": "oc", "name": "Occitan (post 1500)"},
    {"code": "oj", "name": "Ojibwa"},
    {"code": "om", "name": "Oromo"},
    {"code": "or", "name": "Oriya"},
    {"code": "os", "name": "Ossetian; Ossetic"},
    {"code": "pa", "name": "Panjabi; Punjabi"},
    {"code": "pi", "name": "Pali"},
    {"code": "pl", "name": "Polish"},
    {"code": "ps", "name": "Pushto; Pashto"},
    {"code": "pt", "name": "Portuguese"},
    {"code": "qu", "name": "Quechua"},
    {"code": "rm", "name": "Romansh"},
    {"code": "rn", "name": "Rundi"},
    {"code": "ro", "name": "Romanian; Moldavian; Moldovan"},
    {"code": "ru", "name": "Russian"},
    {"code": "rw", "name": "Kinyarwanda"},
    {"code": "sa", "name": "Sanskrit"},
    {"code": "sc", "name": "Sardinian"},
    {"code": "sd", "name": "Sindhi"},
    {"code": "se", "name": "Northern Sami"},
    {"code": "sg", "name": "Sango"},
    {"code": "si", "name": "Sinhala; Sinhalese"},
    {"code": "sk", "name": "Slovak"},
    {"code": "sl", "name": "Slovenian"},
    {"code": "sm", "name": "Samoan"},
    {"code": "sn", "name": "Shona"},
    {"code": "so", "name": "Somali"},
    {"code": "sq", "name": "Albanian"},
    {"code": "sr", "name": "Serbian"},
    {"code": "ss", "name": "Swati"},
    {"code": "st", "name": "Sotho, Southern"},
    {"code": "su", "name": "Sundanese"},
    {"code": "sv", "name": "Swedish"},
    {"code": "sw", "name": "Swahili"},
    {"code": "ta", "name": "Tamil"},
    {"code": "te", "name": "Telugu"},
    {"code": "tg", "name": "Tajik"},
    {"code": "th", "name": "Thai"},
    {"code": "ti", "name": "Tigrinya"},
    {"code": "tk", "name": "Turkmen"},
    {"code": "tl", "name": "Tagalog"},
    {"code": "tn", "name": "Tswana"},
    {"code": "to", "name": "Tonga (Tonga Islands)"},
    {"code": "tr", "name": "Turkish"},
    {"code": "ts", "name": "Tsonga"},
    {"code": "tt", "name": "Tatar"},
    {"code": "tw", "name": "Twi"},
    {"code": "ty", "name": "Tahitian"},
    {"code": "ug", "name": "Uighur; Uyghur"},
    {"code": "uk", "name": "Ukrainian"},
    {"code": "ur", "name": "Urdu"},
    {"code": "uz", "name": "Uzbek"},
    {"code": "ve", "name": "Venda"},
    {"code": "vi", "name": "Vietnamese"},
    {"code": "vo", "name": "Volapük"},
    {"code": "wa", "name": "Walloon"},
    {"code": "wo", "name": "Wolof"},
    {"code": "xh", "name": "Xhosa"},
    {"code": "yi", "name": "Yiddish"},
    {"code": "yo", "name": "Yoruba"},
    {"code": "za", "name": "Zhuang; Chuang"},
    {"code": "zh", "name": "Chinese"},
    {"code": "zu", "name": "Zulu"}
]
export const Gender = [
    {id: 1, label: "Male", value: "male"},
    {id: 2, label: "Female", value: "female"},
    {id: 3, label: "Transgender Male / Trans Man", value: "Transgender Male / Trans Man"},
    {id: 4, label: "Transgender Female / Trans Woman", value: "Transgender Female / Trans Woman"},
    {id: 5, label: "Genderqueer", value: "Genderqueer"},
    {id: 6, label: "Non-Binary", value: "Non-Binary"},
    {id: 7, label: "Agender", value: "Agender"},
    {id: 8, label: "Bigender", value: "Bigender"},
    {id: 9, label: "Genderfluid", value: "Genderfluid"},
    {id: 10, label: "Androgynous", value: "Androgynous"},
    {id: 11, label: "Intersex", value: "Intersex"},
    {id: 12, label: "Two-Spirit", value: "Two-Spirit"},
    {id: 13, label: "Third Gender", value: "Third Gender"},
    {id: 14, label: "Pangender", value: "Pangender"},
    {id: 15, label: "Gender Nonconforming", value: "Gender Nonconforming"},
    {id: 16, label: "Demiboy", value: "Demiboy"},
    {id: 17, label: "Demigirl", value: "Demigirl"},
    {id: 18, label: "Neutrois", value: "Neutrois"},
    {id: 19, label: "Aporagender", value: "Aporagender"},
    {id: 20, label: "MTF (Male to Female)", value: "MTF (Male to Female)"},
    {id: 21, label: "FTM (Female to Male)", value: "FTM (Female to Male)"},
    {id: 22, label: "Cisgender", value: "Cisgender"},
    {id: 23, label: "Cisgender Male", value: "Cisgender Male"},
    {id: 24, label: "Cisgender Female", value: "Cisgender Female"},
]

export const Religion = [

    {id: 1, label: "Christianity"},
    {id: 2, label: "Islam"},
    {id: 3, label: "Hinduism"},
    {id: 4, label: "Buddhism"},
    {id: 5, label: "Sikhism"},
    {id: 6, label: "Judaism"},
    {id: 7, label: "Baha'i Faith"},
    {id: 8, label: "Jainism"},
    {id: 9, label: "Shinto"},
    {id: 10, label: "Taoism"},
    {id: 11, label: "Confucianism"},
    {id: 12, label: "Zoroastrianism"},
    {id: 13, label: "Paganism"},
    {id: 14, label: "Wicca"},
    {id: 15, label: "Rastafarianism"},
    {id: 16, label: "Scientology"},
    {id: 17, label: "Falun Gong"},
    {id: 18, label: "Indigenous religions"},
    {id: 19, label: "New Age Spiritualism"},
    {id: 20, label: "Atheism"},
    {id: 21, label: "Agnosticism"},
]
const MySwal = withReactContent(Swal)

export const AppSwal: ({value, type}: { value: any; type: any }) => void = ({value, type}) => {
   /* toast(value,{
        type
    })*/
    /*MySwal.fire({
        icon: type,
        title: type,
        text: value,
    })*/
}

export const Countries = [
    {code: 'AD', label: 'Andorra', phone: '376'},
    {
        code: 'AE',
        label: 'United Arab Emirates',
        phone: '971',
    },
    {code: 'AF', label: 'Afghanistan', phone: '93'},
    {
        code: 'AG',
        label: 'Antigua and Barbuda',
        phone: '1-268',
    },
    {code: 'AI', label: 'Anguilla', phone: '1-264'},
    {code: 'AL', label: 'Albania', phone: '355'},
    {code: 'AM', label: 'Armenia', phone: '374'},
    {code: 'AO', label: 'Angola', phone: '244'},
    {code: 'AQ', label: 'Antarctica', phone: '672'},
    {code: 'AR', label: 'Argentina', phone: '54'},
    {code: 'AS', label: 'American Samoa', phone: '1-684'},
    {code: 'AT', label: 'Austria', phone: '43'},
    {
        code: 'AU',
        label: 'Australia',
        phone: '61',
        suggested: true,
    },
    {code: 'AW', label: 'Aruba', phone: '297'},
    {code: 'AX', label: 'Alland Islands', phone: '358'},
    {code: 'AZ', label: 'Azerbaijan', phone: '994'},
    {
        code: 'BA',
        label: 'Bosnia and Herzegovina',
        phone: '387',
    },
    {code: 'BB', label: 'Barbados', phone: '1-246'},
    {code: 'BD', label: 'Bangladesh', phone: '880'},
    {code: 'BE', label: 'Belgium', phone: '32'},
    {code: 'BF', label: 'Burkina Faso', phone: '226'},
    {code: 'BG', label: 'Bulgaria', phone: '359'},
    {code: 'BH', label: 'Bahrain', phone: '973'},
    {code: 'BI', label: 'Burundi', phone: '257'},
    {code: 'BJ', label: 'Benin', phone: '229'},
    {code: 'BL', label: 'Saint Barthelemy', phone: '590'},
    {code: 'BM', label: 'Bermuda', phone: '1-441'},
    {code: 'BN', label: 'Brunei Darussalam', phone: '673'},
    {code: 'BO', label: 'Bolivia', phone: '591'},
    {code: 'BR', label: 'Brazil', phone: '55'},
    {code: 'BS', label: 'Bahamas', phone: '1-242'},
    {code: 'BT', label: 'Bhutan', phone: '975'},
    {code: 'BV', label: 'Bouvet Island', phone: '47'},
    {code: 'BW', label: 'Botswana', phone: '267'},
    {code: 'BY', label: 'Belarus', phone: '375'},
    {code: 'BZ', label: 'Belize', phone: '501'},
    {
        code: 'CA',
        label: 'Canada',
        phone: '1',
    },
    {
        code: 'CC',
        label: 'Cocos (Keeling) Islands',
        phone: '61',
    },
    {
        code: 'CD',
        label: 'Congo, Democratic Republic of the',
        phone: '243',
    },
    {
        code: 'CF',
        label: 'Central African Republic',
        phone: '236',
    },
    {
        code: 'CG',
        label: 'Congo, Republic of the',
        phone: '242',
    },
    {code: 'CH', label: 'Switzerland', phone: '41'},
    {code: 'CI', label: "Cote d'Ivoire", phone: '225'},
    {code: 'CK', label: 'Cook Islands', phone: '682'},
    {code: 'CL', label: 'Chile', phone: '56'},
    {code: 'CM', label: 'Cameroon', phone: '237'},
    {code: 'CN', label: 'China', phone: '86'},
    {code: 'CO', label: 'Colombia', phone: '57'},
    {code: 'CR', label: 'Costa Rica', phone: '506'},
    {code: 'CU', label: 'Cuba', phone: '53'},
    {code: 'CV', label: 'Cape Verde', phone: '238'},
    {code: 'CW', label: 'Curacao', phone: '599'},
    {code: 'CX', label: 'Christmas Island', phone: '61'},
    {code: 'CY', label: 'Cyprus', phone: '357'},
    {code: 'CZ', label: 'Czech Republic', phone: '420'},
    {
        code: 'DE',
        label: 'Germany',
        phone: '49',
    },
    {code: 'DJ', label: 'Djibouti', phone: '253'},
    {code: 'DK', label: 'Denmark', phone: '45'},
    {code: 'DM', label: 'Dominica', phone: '1-767'},
    {
        code: 'DO',
        label: 'Dominican Republic',
        phone: '1-809',
    },
    {code: 'DZ', label: 'Algeria', phone: '213'},
    {code: 'EC', label: 'Ecuador', phone: '593'},
    {code: 'EE', label: 'Estonia', phone: '372'},
    {code: 'EG', label: 'Egypt', phone: '20'},
    {code: 'EH', label: 'Western Sahara', phone: '212'},
    {code: 'ER', label: 'Eritrea', phone: '291'},
    {code: 'ES', label: 'Spain', phone: '34'},
    {code: 'ET', label: 'Ethiopia', phone: '251'},
    {code: 'FI', label: 'Finland', phone: '358'},
    {code: 'FJ', label: 'Fiji', phone: '679'},
    {
        code: 'FK',
        label: 'Falkland Islands (Malvinas)',
        phone: '500',
    },
    {
        code: 'FM',
        label: 'Micronesia, Federated States of',
        phone: '691',
    },
    {code: 'FO', label: 'Faroe Islands', phone: '298'},
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    {code: 'GA', label: 'Gabon', phone: '241'},
    {code: 'GB', label: 'United Kingdom', phone: '44'},
    {code: 'GD', label: 'Grenada', phone: '1-473'},
    {code: 'GE', label: 'Georgia', phone: '995'},
    {code: 'GF', label: 'French Guiana', phone: '594'},
    {code: 'GG', label: 'Guernsey', phone: '44'},
    {code: 'GH', label: 'Ghana', phone: '233'},
    {code: 'GI', label: 'Gibraltar', phone: '350'},
    {code: 'GL', label: 'Greenland', phone: '299'},
    {code: 'GM', label: 'Gambia', phone: '220'},
    {code: 'GN', label: 'Guinea', phone: '224'},
    {code: 'GP', label: 'Guadeloupe', phone: '590'},
    {code: 'GQ', label: 'Equatorial Guinea', phone: '240'},
    {code: 'GR', label: 'Greece', phone: '30'},
    {
        code: 'GS',
        label: 'South Georgia and the South Sandwich Islands',
        phone: '500',
    },
    {code: 'GT', label: 'Guatemala', phone: '502'},
    {code: 'GU', label: 'Guam', phone: '1-671'},
    {code: 'GW', label: 'Guinea-Bissau', phone: '245'},
    {code: 'GY', label: 'Guyana', phone: '592'},
    {code: 'HK', label: 'Hong Kong', phone: '852'},
    {
        code: 'HM',
        label: 'Heard Island and McDonald Islands',
        phone: '672',
    },
    {code: 'HN', label: 'Honduras', phone: '504'},
    {code: 'HR', label: 'Croatia', phone: '385'},
    {code: 'HT', label: 'Haiti', phone: '509'},
    {code: 'HU', label: 'Hungary', phone: '36'},
    {code: 'ID', label: 'Indonesia', phone: '62'},
    {code: 'IE', label: 'Ireland', phone: '353'},
    {code: 'IL', label: 'Israel', phone: '972'},
    {code: 'IM', label: 'Isle of Man', phone: '44'},
    {code: 'IN', label: 'India', phone: '91'},
    {
        code: 'IO',
        label: 'British Indian Ocean Territory',
        phone: '246',
    },
    {code: 'IQ', label: 'Iraq', phone: '964'},
    {
        code: 'IR',
        label: 'Iran, Islamic Republic of',
        phone: '98',
    },
    {code: 'IS', label: 'Iceland', phone: '354'},
    {code: 'IT', label: 'Italy', phone: '39'},
    {code: 'JE', label: 'Jersey', phone: '44'},
    {code: 'JM', label: 'Jamaica', phone: '1-876'},
    {code: 'JO', label: 'Jordan', phone: '962'},
    {
        code: 'JP',
        label: 'Japan',
        phone: '81',
    },
    {code: 'KE', label: 'Kenya', phone: '254'},
    {code: 'KG', label: 'Kyrgyzstan', phone: '996'},
    {code: 'KH', label: 'Cambodia', phone: '855'},
    {code: 'KI', label: 'Kiribati', phone: '686'},
    {code: 'KM', label: 'Comoros', phone: '269'},
    {
        code: 'KN',
        label: 'Saint Kitts and Nevis',
        phone: '1-869',
    },
    {
        code: 'KP',
        label: "Korea, Democratic People's Republic of",
        phone: '850',
    },
    {code: 'KR', label: 'Korea, Republic of', phone: '82'},
    {code: 'KW', label: 'Kuwait', phone: '965'},
    {code: 'KY', label: 'Cayman Islands', phone: '1-345'},
    {code: 'KZ', label: 'Kazakhstan', phone: '7'},
    {
        code: 'LA',
        label: "Lao People's Democratic Republic",
        phone: '856',
    },
    {code: 'LB', label: 'Lebanon', phone: '961'},
    {code: 'LC', label: 'Saint Lucia', phone: '1-758'},
    {code: 'LI', label: 'Liechtenstein', phone: '423'},
    {code: 'LK', label: 'Sri Lanka', phone: '94'},
    {code: 'LR', label: 'Liberia', phone: '231'},
    {code: 'LS', label: 'Lesotho', phone: '266'},
    {code: 'LT', label: 'Lithuania', phone: '370'},
    {code: 'LU', label: 'Luxembourg', phone: '352'},
    {code: 'LV', label: 'Latvia', phone: '371'},
    {code: 'LY', label: 'Libya', phone: '218'},
    {code: 'MA', label: 'Morocco', phone: '212'},
    {code: 'MC', label: 'Monaco', phone: '377'},
    {
        code: 'MD',
        label: 'Moldova, Republic of',
        phone: '373',
    },
    {code: 'ME', label: 'Montenegro', phone: '382'},
    {
        code: 'MF',
        label: 'Saint Martin (French part)',
        phone: '590',
    },
    {code: 'MG', label: 'Madagascar', phone: '261'},
    {code: 'MH', label: 'Marshall Islands', phone: '692'},
    {
        code: 'MK',
        label: 'Macedonia, the Former Yugoslav Republic of',
        phone: '389',
    },
    {code: 'ML', label: 'Mali', phone: '223'},
    {code: 'MM', label: 'Myanmar', phone: '95'},
    {code: 'MN', label: 'Mongolia', phone: '976'},
    {code: 'MO', label: 'Macao', phone: '853'},
    {
        code: 'MP',
        label: 'Northern Mariana Islands',
        phone: '1-670',
    },
    {code: 'MQ', label: 'Martinique', phone: '596'},
    {code: 'MR', label: 'Mauritania', phone: '222'},
    {code: 'MS', label: 'Montserrat', phone: '1-664'},
    {code: 'MT', label: 'Malta', phone: '356'},
    {code: 'MU', label: 'Mauritius', phone: '230'},
    {code: 'MV', label: 'Maldives', phone: '960'},
    {code: 'MW', label: 'Malawi', phone: '265'},
    {code: 'MX', label: 'Mexico', phone: '52'},
    {code: 'MY', label: 'Malaysia', phone: '60'},
    {code: 'MZ', label: 'Mozambique', phone: '258'},
    {code: 'NA', label: 'Namibia', phone: '264'},
    {code: 'NC', label: 'New Caledonia', phone: '687'},
    {code: 'NE', label: 'Niger', phone: '227'},
    {code: 'NF', label: 'Norfolk Island', phone: '672'},
    {code: 'NG', label: 'Nigeria', phone: '234'},
    {code: 'NI', label: 'Nicaragua', phone: '505'},
    {code: 'NL', label: 'Netherlands', phone: '31'},
    {code: 'NO', label: 'Norway', phone: '47'},
    {code: 'NP', label: 'Nepal', phone: '977'},
    {code: 'NR', label: 'Nauru', phone: '674'},
    {code: 'NU', label: 'Niue', phone: '683'},
    {code: 'NZ', label: 'New Zealand', phone: '64'},
    {code: 'OM', label: 'Oman', phone: '968'},
    {code: 'PA', label: 'Panama', phone: '507'},
    {code: 'PE', label: 'Peru', phone: '51'},
    {code: 'PF', label: 'French Polynesia', phone: '689'},
    {code: 'PG', label: 'Papua New Guinea', phone: '675'},
    {code: 'PH', label: 'Philippines', phone: '63'},
    {code: 'PK', label: 'Pakistan', phone: '92'},
    {code: 'PL', label: 'Poland', phone: '48'},
    {
        code: 'PM',
        label: 'Saint Pierre and Miquelon',
        phone: '508',
    },
    {code: 'PN', label: 'Pitcairn', phone: '870'},
    {code: 'PR', label: 'Puerto Rico', phone: '1'},
    {
        code: 'PS',
        label: 'Palestine, State of',
        phone: '970',
    },
    {code: 'PT', label: 'Portugal', phone: '351'},
    {code: 'PW', label: 'Palau', phone: '680'},
    {code: 'PY', label: 'Paraguay', phone: '595'},
    {code: 'QA', label: 'Qatar', phone: '974'},
    {code: 'RE', label: 'Reunion', phone: '262'},
    {code: 'RO', label: 'Romania', phone: '40'},
    {code: 'RS', label: 'Serbia', phone: '381'},
    {code: 'RU', label: 'Russian Federation', phone: '7'},
    {code: 'RW', label: 'Rwanda', phone: '250'},
    {code: 'SA', label: 'Saudi Arabia', phone: '966'},
    {code: 'SB', label: 'Solomon Islands', phone: '677'},
    {code: 'SC', label: 'Seychelles', phone: '248'},
    {code: 'SD', label: 'Sudan', phone: '249'},
    {code: 'SE', label: 'Sweden', phone: '46'},
    {code: 'SG', label: 'Singapore', phone: '65'},
    {code: 'SH', label: 'Saint Helena', phone: '290'},
    {code: 'SI', label: 'Slovenia', phone: '386'},
    {
        code: 'SJ',
        label: 'Svalbard and Jan Mayen',
        phone: '47',
    },
    {code: 'SK', label: 'Slovakia', phone: '421'},
    {code: 'SL', label: 'Sierra Leone', phone: '232'},
    {code: 'SM', label: 'San Marino', phone: '378'},
    {code: 'SN', label: 'Senegal', phone: '221'},
    {code: 'SO', label: 'Somalia', phone: '252'},
    {code: 'SR', label: 'Suriname', phone: '597'},
    {code: 'SS', label: 'South Sudan', phone: '211'},
    {
        code: 'ST',
        label: 'Sao Tome and Principe',
        phone: '239',
    },
    {code: 'SV', label: 'El Salvador', phone: '503'},
    {
        code: 'SX',
        label: 'Sint Maarten (Dutch part)',
        phone: '1-721',
    },
    {
        code: 'SY',
        label: 'Syrian Arab Republic',
        phone: '963',
    },
    {code: 'SZ', label: 'Swaziland', phone: '268'},
    {
        code: 'TC',
        label: 'Turks and Caicos Islands',
        phone: '1-649',
    },
    {code: 'TD', label: 'Chad', phone: '235'},
    {
        code: 'TF',
        label: 'French Southern Territories',
        phone: '262',
    },
    {code: 'TG', label: 'Togo', phone: '228'},
    {code: 'TH', label: 'Thailand', phone: '66'},
    {code: 'TJ', label: 'Tajikistan', phone: '992'},
    {code: 'TK', label: 'Tokelau', phone: '690'},
    {code: 'TL', label: 'Timor-Leste', phone: '670'},
    {code: 'TM', label: 'Turkmenistan', phone: '993'},
    {code: 'TN', label: 'Tunisia', phone: '216'},
    {code: 'TO', label: 'Tonga', phone: '676'},
    {code: 'TR', label: 'Turkey', phone: '90'},
    {
        code: 'TT',
        label: 'Trinidad and Tobago',
        phone: '1-868',
    },
    {code: 'TV', label: 'Tuvalu', phone: '688'},
    {
        code: 'TW',
        label: 'Taiwan',
        phone: '886',
    },
    {
        code: 'TZ',
        label: 'United Republic of Tanzania',
        phone: '255',
    },
    {code: 'UA', label: 'Ukraine', phone: '380'},
    {code: 'UG', label: 'Uganda', phone: '256'},
    {
        code: 'US',
        label: 'United States',
        phone: '1',
    },
    {code: 'UY', label: 'Uruguay', phone: '598'},
    {code: 'UZ', label: 'Uzbekistan', phone: '998'},
    {
        code: 'VA',
        label: 'Holy See (Vatican City State)',
        phone: '379',
    },
    {
        code: 'VC',
        label: 'Saint Vincent and the Grenadines',
        phone: '1-784',
    },
    {code: 'VE', label: 'Venezuela', phone: '58'},
    {
        code: 'VG',
        label: 'British Virgin Islands',
        phone: '1-284',
    },
    {
        code: 'VI',
        label: 'US Virgin Islands',
        phone: '1-340',
    },
    {code: 'VN', label: 'Vietnam', phone: '84'},
    {code: 'VU', label: 'Vanuatu', phone: '678'},
    {code: 'WF', label: 'Wallis and Futuna', phone: '681'},
    {code: 'WS', label: 'Samoa', phone: '685'},
    {code: 'XK', label: 'Kosovo', phone: '383'},
    {code: 'YE', label: 'Yemen', phone: '967'},
    {code: 'YT', label: 'Mayotte', phone: '262'},
    {code: 'ZA', label: 'South Africa', phone: '27'},
    {code: 'ZM', label: 'Zambia', phone: '260'},
    {code: 'ZW', label: 'Zimbabwe', phone: '263'},
];
