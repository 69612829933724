import {Box, Typography} from "@mui/material";
import FirstSlideImage from "../../../assets/images/slider/slide-1.png";
import React from "react";

const FirstSlide = () =>{
    return(
        <Box className={"slider-style"} sx={{padding:"20px"}}>

            <Typography variant="h3" className="slider-title">Facilitate Understanding and Empathy</Typography>
            <Box className="flex justify-center">
                <img src={FirstSlideImage} style={{maxWidth:"100%" , height:"auto"}} alt="first-slide"/>
            </Box>
            <Typography className="slider-content">
                Mitraria Platform is built to facilitate understanding and empathy among users by connect individuals
                who have gone through similar experiences or share specific emotional needs through it is personalized
                matching system and emphasis on shared experiences, Mitraria enables us users to find validation,
                acceptance, and support from like-minded individuals within the community.</Typography>
        </Box>
    )
}

export default React.memo(FirstSlide)