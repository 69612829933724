import {SET_RELATION_LIST} from "../actions";
import {SEARCH_RELATION} from "./action";

interface SetInitialized {
    type: string;
    data: any
    clone?: any,
    search?: any

}

type ActionType = SetInitialized;

const initializedState = {
    data: null,
    search: null,
    clone: null
}


const reducer = (state = initializedState, action: ActionType) => {
    let {data} = action;
    switch (action?.type) {
        case SET_RELATION_LIST:
            return {
                data,
                clone: data,
            };
        case SEARCH_RELATION:

            const {search} = action;
            let clone: any = state.clone
            data = Object.entries(clone).filter(([key, value]: any) => {
                let result = false;
                result = value.username.toLowerCase().includes(search.toLowerCase());
                return result;
            })
                .reduce((acc: any, [k, v]: any) => ({...acc, [k]: v}), {});

            data = Object.values(data)
            return {
                data,
                clone,
                search
            }
        default:
            return state;
    }
};

export default reducer;
