import {SET_CHAT_LIST} from "../actions";
import {date, object} from "yup";
import {SEARCH_CHAT} from "./action";

interface SetInitialized {
    type: string;
    data: any,
    clone?: any
    search?: any
}

type ActionType = SetInitialized;

const initializedState = {
    data: null,
    search: null,
    clone: null
};

const reducer = (state = initializedState, action: ActionType) => {
    let {data} = action;
    switch (action?.type) {
        case SET_CHAT_LIST:
            return {
                data: data?.data,
                clone: data.data,
            };
        case SEARCH_CHAT:
            const {search} = action;
            let clone: any = state.clone
            data = Object.entries(clone).filter(([key, value]: any) => {
                let result = false;
                result = value.participants[0].username.toLowerCase().includes(search.toLowerCase());
                if (value?.data?.title != null)
                    result = value?.data?.title.toLowerCase().includes(search)

                return result;
            })
                .reduce((acc: any, [k, v]: any) => ({...acc, [k]: v}), {});

            data = Object.values(data)
            return {
                data: data,
                clone,
                search
            }
        default:
            return state;
    }
};

export default reducer;
