import {Alert, Box, Grid, Stack, Typography} from "@mui/material";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import React, {useState} from "react";
import AppLogo from "../../components/AppLogo";
import AppBackButton from "../../components/AppBackButton";
import {AppSwal} from "../../global/functions";
import AppSelectedTag from "../../sections/AppSelectedTag";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {ADD_TAG, REMOVE_TAG , TOGGLE_PAGE} from "../../redux/tags/action";
import {EnterTag} from "../../redux/tags/action";
import Logo from "../../components/AppLogo";

const AuthEnterTag = () => {
    const state: any = useSelector(state => state)
    const [tagNameForm, setTagNameForm] = useState<any>({
        tag: null
    })

    const handlerAddNewTag = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newTag = {id: null, title: tagNameForm?.tag};
        if (!state.tags.some((tag: any) => tag.title === newTag.title)) {
            store.dispatch({
                type: ADD_TAG,
                data: newTag
            })
        } else {
            AppSwal({value: "Tag already exists in the list.", type: "warning"})
        }
        setTagNameForm((state: any) => ({
            ...state,
            tag: "",
        }))
    }

    const handlerRemoveSelectTag = (item: any) => {
        store.dispatch({
            type: REMOVE_TAG,
            data: item
        });
    };

    const handlerSelectTag = (value: any) => {
        setTagNameForm((state: any) => ({
            ...state,
            tag: value,
        }))
    }

    const handlerEnterTag = () => {
        EnterTag(state.tags)
    }

    return (
        <Box className="auth-bg">
            <Grid container sx={{height: "100%"}}>
                <Box className="back-button-icon" sx={{left: {lg: "50px", xs: "20px"}}}>
                    <AppBackButton onClick={() => {
                        store.dispatch({
                            type: TOGGLE_PAGE
                        })
                    }}/>
                </Box>
                <Grid item
                      xs={10}
                      sm={6}
                      md={8}
                      mx="auto"
                      sx={{
                          scale: "0.8",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          height: "100%"
                      }}>
                    <Box sx={{textAlign: "center", marginBottom: "32px"}}>
                        <Typography variant="h4" className="login-header">Enter Tags</Typography>
                        <Stack sx={{mb: 2, width: '175', height: '82px'}}>
                            <Logo/>
                        </Stack>
                    </Box>

                    <Box sx={{textAlign: "center"}}>
                        <Typography className="enter-tag-detail">Define yourself as you know and want to
                            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisiut aliquip ex ea commodo consequat.</Typography>
                    </Box>
                    {
                        state?.error !== null && state?.error.map((item:any)=>(
                            <Alert severity="error" sx={{mb:2}}>{item}</Alert>
                        ))
                    }
                    <Box
                        component="form"
                        onSubmit={handlerAddNewTag}
                        autoComplete="off"
                    >
                        <AppInput
                            placeholder="Type your custom tag and press ENTER"
                            onChange={handlerSelectTag}
                            value={tagNameForm?.tag}
                        />
                    </Box>
                    <Box sx={{marginTop: "25px"}}>
                        <AppSelectedTag handlerRemoveSelectTag={handlerRemoveSelectTag}/>
                    </Box>
                    <Box sx={{width: {md: "50%", xs: "90%"}}} className="next-button">
                        <AppButton onClick={handlerEnterTag} value="Done" type="submit"/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default React.memo(AuthEnterTag)
