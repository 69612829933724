import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {InputLabel} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";

interface RHFSelectProps {
    attribute: {
        label?: string;
        className?: string;
        items: any;
        displayKey: string;
        InputValue: string;
        color?: "white" | "gray";
        name: string;
        placeholder?: string;
        defaultValue?: any
    };
}

const RHFSelect: React.FC<RHFSelectProps> = ({attribute}) => {
    const {control} = useFormContext();
    return (
        <React.Fragment>
            {attribute.label && (
                <InputLabel className="auth-input">
                    {attribute.label}
                </InputLabel>
            )}

            <Controller
                name={attribute.name}
                control={control}
                render={({field = {} as any, fieldState: {error}}) => (
                    <Autocomplete
                        options={attribute.items}
                        getOptionLabel={(option: any) => {
                            return option[attribute.displayKey] || option;
                        }}
                        className={attribute.className}
                        onChange={(event: any, newValue: any) => {
                            field.onChange(newValue ? newValue[attribute.displayKey] || '' : '');
                        }}

                        value={field?.value}
                        renderInput={(params) => (
                            <TextField
                                {...field}
                                {...params}
                                error={!!error}
                                value={field.value}
                                placeholder={attribute.placeholder}
                                helperText={error?.message}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        background: attribute.color === "gray" ? "#F0F0F0" : "#FFF",
                                        borderRadius: "50px",
                                        width: "100%",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "unset !important",
                                    },
                                }}
                            />
                        )}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default React.memo(RHFSelect);
