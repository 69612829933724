import {call, put, takeLatest, select} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from 'axios';
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {
    COMMENT_POST, DELETE_POST, FETCH_EXPLORE_POST_LIST,
    FETCH_MY_POST_LIST,
    FETCH_OTHERS_POST_LIST, INCREMENT_LIKE_POST,
    LIKE_POST, REPLAY_COMMENT_POST,
    SHOW_POST_LIST,
    TOGGLE_BOOKMARK_POST, UPDATE_COMMENT, UPDATE_COMMENT_REPLY, UPDATE_CURRENT_PAGE,
    UPLOAD_POST
} from "./action";
import {FetchBookmarkList} from "../bookmark/action";
import {AppSwal} from "../../global/functions";
import {RootState} from "../types";
import {Initialize} from "../../guards/initialize";

export function* FetchMyPostList(action?: PayloadAction<{ page?: any }>): Generator<any, void, any> {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.POST_LIST, {"page": action?.payload?.page});
        if (response) {
            if (action?.payload?.page == "1" || !action) {
                yield put({
                    type: SHOW_POST_LIST,
                    data: response?.data?.data,
                    links: response?.data?.links
                });
            } else {
                const newData = response?.data?.data;
                const currentState = yield select((state: RootState) => state.posts?.data);
                const combinedData = [...currentState, ...newData];

                yield put({
                    type: SHOW_POST_LIST,
                    data: combinedData,
                    links: response?.data?.links
                });
            }

            yield put({
                type: UPDATE_CURRENT_PAGE,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* FetchOtherPostList(action?: PayloadAction<{ page?: any }>): Generator<any, void, any> {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.HOME_POST_LIST, {"page": action?.payload?.page})
        if (response) {
            if (action?.payload?.page == "1" || !action) {
                yield put({
                    type: SHOW_POST_LIST,
                    data: response?.data?.data,
                    links: response?.data?.links
                });
            } else {
                const newData = response?.data?.data;
                const currentState = yield select((state: RootState) => state.posts?.data);
                const combinedData = [...currentState, ...newData];

                yield put({
                    type: SHOW_POST_LIST,
                    data: combinedData,
                    links: response?.data?.links
                });
            }

            yield put({
                type: UPDATE_CURRENT_PAGE,
            });
        }
    } catch (error) {
        console.log(error)
    }
}

export function* FetchExplore(action: PayloadAction<{ page: any }>): Generator<any, void, any> {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.EXPLORE_POST_LIST, {"page": action?.payload?.page})
        if (response) {
            if (action?.payload?.page == "1") {
                yield put({
                    type: SHOW_POST_LIST,
                    data: response?.data?.data,
                    links: response?.data?.links
                });
            } else {
                const newData = response?.data?.data;
                const currentState = yield select((state: RootState) => state.posts?.data);
                const combinedData = [...currentState, ...newData];

                yield put({
                    type: SHOW_POST_LIST,
                    data: combinedData,
                    links: response?.data?.links
                });
            }

            yield put({
                type: UPDATE_CURRENT_PAGE,
            });
        }
    } catch (error) {
        console.log(error)
    }
}

export function* LikePost(action: PayloadAction<{ type: string, id: any }>): any {

    try {
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.LIKE, action?.payload)

        if (response) {

            const currentState = yield select((state: RootState) => state?.posts?.data);
            yield put({
                type: INCREMENT_LIKE_POST,
                data: currentState,
                id: action.payload.id,
            });
        }
    } catch (error) {
        console.log(error)
    }
}

export function* DeletePost(action: PayloadAction<{ id: any }>): any {
    try {
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.POST_DELETE, action?.payload)
        if (response) {
            yield call(FetchMyPostList)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* CommentPost(action: PayloadAction<{ id: any, comment: string }>): any {
    try {
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.COMMENT, {
            "type": "Post",
            "id": action?.payload?.id,
            "comment": action?.payload?.comment
        })
        const currentState = yield select((state: RootState) => state?.posts?.data);

        if (response) {
            yield put({
                type: "SET_COMMENT",
                data: null
            })
            yield put({
                type: UPDATE_COMMENT,
                id: action?.payload?.id,
                comment: response.data.comment,
                data: currentState
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export function* ReplayCommentPost(action: PayloadAction<{ id: any, comment: string }>): any {
    try {

        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.COMMENT, {
            "type": "Comment",
            "id": action?.payload?.id,
            "comment": action?.payload?.comment
        })
        const currentState = yield select((state: RootState) => state?.posts?.data);

        if (response) {
            yield put({
                type: "SET_COMMENT",
                data: null
            })
            yield put({
                type: UPDATE_COMMENT_REPLY,
                id: action?.payload?.id,
                parent_id: response.data.parent_id,
                comment: response.data.comment,
                data: currentState
            })
            if (window.location.pathname === "/app/profile" || window.location.pathname === "/app/users") {
                // yield call(FetchMyPostList)
            } else if (window.location.pathname === "/app/dashboard") {
                // yield call(FetchOtherPostList)
            } else {
                yield call(FetchBookmarkList)
            }
        }
    } catch (error) {
        console.log(error)
    }
}


export function* ToggleBookMarkPost(action: PayloadAction<{ post: any }>) {
    try {
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.TOGGLE_BOOKMARK, action?.payload)
        if (response) {
            AppSwal({type: "success", value: "this post post add to bookmarks"})
            if (window.location.pathname === "/app/profile" || window.location.pathname === "/app/users") {
                // yield call(FetchMyPostList)
            } else if (window.location.pathname === "/app/dashboard") {
                // yield call(FetchOtherPostList)
            } else {
                yield call(FetchBookmarkList)
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export function* UploadPost(action: PayloadAction<{ isPublic: any, type: string, media: any, caption: string }>) {
    try {
        const formData = new FormData();
        formData.append('caption', action?.payload?.caption);
        formData.append('public', action?.payload?.isPublic === true ? "1" : "0");
        if (action?.payload?.type === "image")
            action?.payload?.media?.map((item: any) => {
                formData.append('images[]', item);
            })
        else if (action?.payload?.type === "video")
            formData.append('videos[]', action?.payload?.media);
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.UPLOAD_POST, formData)
        if (response) {

            yield call(FetchOtherPostList)
            yield call(Initialize)
            AppSwal({value: "Post uploaded successfully", type: "success"})
        }
    } catch (error) {
        console.log(error)
    }
}

function* postSaga() {
    yield takeLatest(FETCH_MY_POST_LIST, FetchMyPostList);
    yield takeLatest(FETCH_OTHERS_POST_LIST, FetchOtherPostList);
    yield takeLatest(FETCH_EXPLORE_POST_LIST, FetchExplore);
    yield takeLatest(LIKE_POST, LikePost);
    yield takeLatest(DELETE_POST, DeletePost);
    yield takeLatest(COMMENT_POST, CommentPost);
    yield takeLatest(REPLAY_COMMENT_POST, ReplayCommentPost);
    yield takeLatest(UPLOAD_POST, UploadPost);
    yield takeLatest(TOGGLE_BOOKMARK_POST, ToggleBookMarkPost);
}

export default postSaga
