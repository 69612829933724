/***
 * Function Root And Sub Link
 * @param root
 * @param sublink
 * @returns {string}
 */

function path(root: any, sublink: any) {
    return `${root}${sublink}`;
}

/***
 * Add Route Constant
 * @type {string}
 */

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';

/***
 * PATH AUTH ROUTE
 * @type {{root: string, verify: string, login: string, forgotPassword: string}}
 */

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    verify: path(ROOTS_AUTH, '/verify'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    resetPassword: (id: any) => path(ROOTS_AUTH, `/reset-password/${id}`),
    googleCallback: (id: any) => path(ROOTS_AUTH, `/google/callback${id}`),
    facebookCallback: (id: any) => path(ROOTS_AUTH, `/facebook/callback${id}`),
    emailVerify: path(ROOTS_AUTH, `/email-verify`),
    waitingToEmailVerify: path(ROOTS_AUTH, `/waiting-to-email-verify`),
    createProfile: path(ROOTS_AUTH, '/create-profile'),
    selectTag: path(ROOTS_AUTH, '/select-tag'),
    enterTag: path(ROOTS_AUTH, '/enter-tag'),
}

/***
 * PATH PAGE 404
 * @type {{page404: string}}
 */

export const PATH_PAGE = {
    page404: '/404',
}

/***
 * PATH DASHBOARD ROUTE
 * @type {{cars: string, companies: string, tickets: string, root: string, banners: string, dashboard: string, notifications: string}}
 */

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    profile: path(ROOTS_DASHBOARD, '/profile'),
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    users: path(ROOTS_DASHBOARD, '/users'),
    bookmark: path(ROOTS_DASHBOARD, '/bookmark'),
    chat: path(ROOTS_DASHBOARD, '/AppChat'),
}
