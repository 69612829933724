import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import configureStore from "./redux/store";
import {Provider} from "react-redux";
import AuthProvider from "./provider/AuthProvider";
import {Bounce, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            theme="light"
            transition={Bounce}
        />
        <App/>
        <ToastContainer/>

    </>
);
