import {Grid, InputLabel} from "@mui/material";
import RHFSelect from "./RHFSelect";
import {Days, Month, Years} from "../../global/functions";
import React from "react";

interface RHFBirthDateProps {
    color?:any;
    daysDefaultValue?:any;
    monthDefaultValue?:any;
    yearDefaultValue?:any;
}
const RHFBirthDate:React.FC<RHFBirthDateProps> = ({color= "gray" , daysDefaultValue , monthDefaultValue , yearDefaultValue}) =>{
    return(
        <React.Fragment>
            <InputLabel className="auth-input">
                Date of birth*
            </InputLabel>
            <Grid container spacing={2}>
                <Grid item xs={3} md={3}>
                    <RHFSelect
                        attribute={{
                            items:Days,
                            displayKey:"label",
                            InputValue:"label",
                            name:"day",
                            color:color,
                            placeholder:"01",
                            defaultValue:daysDefaultValue
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={5}>
                    <RHFSelect
                        attribute={{
                            items:Month,
                            displayKey:"label",
                            InputValue:"value",
                            name:"month",
                            color:color,
                            placeholder:"January",
                            defaultValue:monthDefaultValue
                        }}
                    />
                </Grid>
                <Grid item xs={4} md={4}>
                    <RHFSelect
                        attribute={{
                            items:Years,
                            displayKey:"label",
                            InputValue:"label",
                            name:"year",
                            color:color,
                            placeholder:"2023",
                            defaultValue:yearDefaultValue
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default React.memo(RHFBirthDate)
