import {Box, Grid} from "@mui/material";
import React from "react";
import AuthSelectTag from "../../../sections/AuthSelectTag";
import AuthSlider from "../../../sections/AuthSlider";


export default function SelectTag(){
    return(
        <Box className="auth">
            <Grid container>
                <Grid item lg={6} xs={12} sx={{height:"100vh"}}>
                    <AuthSelectTag/>
                </Grid>
                <Grid item lg={6} sx={{height:{lg: "100vh"}, display: {xs:'none', lg:'block'}}}>
                    <AuthSlider/>
                </Grid>
            </Grid>
        </Box>
    )
}
