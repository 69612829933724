import axiosInstance from "./boot/axios";

export const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST || '';
export const WEBSOCKET_PORT = process.env.REACT_APP_WEBSOCKET_PORT || '';
export const PUSHER_CONFIG = (): any => {
    const token = window.localStorage.getItem('token');
    return {
        encrypted: false,
        authEndpoint: 'https://' + WEBSOCKET_HOST + ':3000/broadcasting/auth',
        cluster: "mt1",
        wsHost: WEBSOCKET_HOST,
        wsPort: WEBSOCKET_PORT,
        wssHost: WEBSOCKET_HOST,
        wssPort: WEBSOCKET_PORT,
        forceTLS: true,
        disableStats: true,
        auth: {
            headers: {
                "Authorization":  token,
                "Access-Control-Allow-Origin": "*",
                'Accept':'Application/json'
            }
        }
    }
}
