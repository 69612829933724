import store from "../../redux/store";
import {CREATE_PROFILE, GET_LOCATION, SEARCH, UPDATE_PROFILE, UPLOAD_AVATAR} from "../actions";

export const UpdateProfile = (data:any ) => {
    store.dispatch({
        type: UPDATE_PROFILE,
        payload: {data}
    })
}

export const CreateProfile = (data:any ) => {
    store.dispatch({
        type: CREATE_PROFILE,
        payload: {data }
    })
}


export const UploadAvatar = (avatar:any) => {
    store.dispatch({
        type: UPLOAD_AVATAR,
        payload: {avatar}
    })
}

export const GetLocation = () => {
    store.dispatch({
        type: GET_LOCATION,
    })
}
export const Search = (search:any) => {
    store.dispatch({
        type: SEARCH,
        payload: {search}
    })
}
