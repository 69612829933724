import {useFormContext, Controller} from 'react-hook-form';
import {InputLabel, TextField} from '@mui/material';
import React from "react";

interface RHFTextFieldProps {
    attribute: {
        name: string,
        label?: string,
        readOnly?: boolean,
        color?: "white" | "gray",
        type?: any,
        focus?: any,
        multiline?: boolean,
        rows?: number,
        placeholder?: string,
        autocomplete?: string,
        className?: string,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void, // اصلاح تایپ onChange
    },
}

const RHFTextField: React.FC<RHFTextFieldProps> = ({attribute, ...other}) => {
    const {control} = useFormContext();

    return (
        <React.Fragment>
            {attribute.label ? <InputLabel className="auth-input">{attribute.label}</InputLabel> : null}
            <Controller
                name={attribute.name}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        {...field}
                        fullWidth
                        autoFocus={attribute?.focus ?? false}
                        error={!!error}
                        type={attribute.type}
                        /*  onChange={(e:React.ChangeEvent<any>) => {
                              field.onChange(e);
                              attribute?.onChange?.(e);
                          }}*/
                        multiline={attribute?.multiline}
                        rows={attribute?.rows}
                        placeholder={attribute?.placeholder}
                        autoComplete={attribute?.autocomplete}
                        className={attribute?.className}
                        sx={{
                            "& .MuiInputBase-root": {
                                background: attribute.color === "gray" ? "#F0F0F0" : "#FFF",
                                borderRadius: "50px",
                                width: "100%",
                                padding: "0"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "unset !important",
                            },
                            "& .MuiInputBase-input": {
                                padding: "13.5px 14px !important"

                            },
                        }}
                        helperText={error?.message}
                        {...other}
                    />
                )}
            />
        </React.Fragment>
    );
}

export default React.memo(RHFTextField);
