// reducer.ts
import {GET_COUNTRY, GET_CITY} from "../actions";

interface SetInitialized {
    type: string;
    data: {
        country: any;
        cities: any;
        selectedLocation: any
    };
}

type ActionType = SetInitialized;

const initializedState = {
    country: null,
    cities: null,
    selectedLocation: null
};

const reducer = (state = initializedState, action: ActionType) => {
    const {data} = action;
    switch (action?.type) {
        case GET_COUNTRY:
            return {
                ...state,
                country: data.country,
                cities: data.cities
            };
        case GET_CITY:
            return {
                selectedLocation:data.selectedLocation,
                country: data.country,
                cities: data.cities
            };

        default:
            return state;
    }
};

export default reducer;
