import {Box, Typography} from "@mui/material";
import SecondSlideImage from "../../../assets/images/slider/slide-2.png";
import React from "react";

const SecondSlide = () =>{
    return(
        <Box className={"slider-style"} sx={{padding:"20px"}}>
            <Typography variant="h3" className="slider-title">Alleviate Loneliness and create a supportive community</Typography>
            <Box className="flex justify-center">
                <img src={SecondSlideImage} style={{maxWidth:"100%" , height:"auto"}} alt="second-slide"/>
            </Box>
            <Typography className="slider-content">
                Mithraria seeks to alleviate feelings of loneliness by establishing a supportive community through
                it is dedicated platform. By offering features and initiatives specifically designed for companionship
                during holidays and special occasions, Mithrariaaims to create a space where users can find solace,
                support and a sense of belonging.</Typography>
        </Box>
    )
}

export default React.memo(SecondSlide)