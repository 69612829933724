import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import FirstSlide from "../../sections/AuthSlider/components/first-slide";
import SecondSlide from "../../sections/AuthSlider/components/second-slide";
import ThirdSlide from "../../sections/AuthSlider/components/third-slide";
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css/pagination';

const AuthSlider = () =>{
    return(
        <Swiper
            modules={[Autoplay , Pagination]}
            slidesPerView={1}
            spaceBetween={20}
            pagination={{ clickable: true }}
            className="auth-slider"
            loop={true}
            autoplay={{
                delay: 10000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
            }}
        >
            <SwiperSlide>
                <FirstSlide/>
            </SwiperSlide>
            <SwiperSlide>
                <SecondSlide/>
            </SwiperSlide>
            <SwiperSlide>
                <ThirdSlide/>
            </SwiperSlide>
        </Swiper>
    )
}

export default React.memo(AuthSlider)