import {useEffect, useState} from "react";
import {INITIALIZE} from "../redux/actions";
import {useSelector} from "react-redux";
import {Initialize} from "../guards/initialize";

export default function AuthProvider({children}: any) {
    const token:any = window.localStorage.getItem("token")
    const state: any = useSelector(state => state)
    useEffect(() => {
        Initialize()
    }, []);



    return (
        <>
            {children}
        </>
    )
}
