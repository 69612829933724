import {useSelector} from "react-redux";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {Navigate} from "react-router-dom";
import SignIn from "../pages/auth/sign-in";
import WaitingToVerifyEmail from "../pages/auth/waiting-to-verify-email";
import CreateProfile from "../pages/auth/create-profile";
import SelectTag from "../pages/app/tags/select-tag";
import {PATH_AUTH} from "../routes/paths";
import Tags from "../pages/app/tags";
import Loader from "../components/Loader/Loader";

export default function AuthGuard({children}: any) {
    const state: any = useSelector(state => state)
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<any>(null);

     if (!state.initialized.initialized) {
         if (pathname !== requestedLocation) {
             setRequestedLocation(pathname);
         }
         return <Loader/>
     }
    if (!state.initialized.isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <SignIn/>
    }
    if (!state.initialized.isVerified) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <WaitingToVerifyEmail/>

    }
    if (state?.initialized?.user?.profile === null) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <CreateProfile/>
    }

    if (!state.initialized.isSelectedTag) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Tags/>
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }
    return <>{children}</>;
}

