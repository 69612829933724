import {Box, Typography} from "@mui/material";
import ThirdSlideImage from "../../../assets/images/slider/slide-3.png";
import React from "react";

const ThirdSlide = () =>{
    return(
        <Box className={"slider-style"} sx={{padding:"20px"}}>
            <Typography variant="h3" className="slider-title">Foster Authentic Connections and Empathy</Typography>
            <Box className="flex justify-center">
                <img src={ThirdSlideImage} style={{maxWidth:"100%" , height:"auto"}} alt="third-slide"/>
            </Box>
            <Typography className="slider-content">
                Mithraria primary mission is to foster authentic connections by creating a unique social media
                platform. By utilizing a pragmatic approach to social networking, Mithraria aims to go beyond
                surface-level interactions and provide users with a platform that facilitates real, meaningful,
                and deep connections between individuals</Typography>
        </Box>
    )
}

export default React.memo(ThirdSlide)