import {styled} from '@mui/material/styles';
import {Box, Button, Typography, Container, Stack, Link} from '@mui/material';
import Logo from "../components/AppLogo";
import {Link as RouterLink} from 'react-router-dom';

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));


export default function Page404() {

    return (
        <RootStyle>
            <Container>
                <Box sx={{maxWidth: 520, margin: 'auto', textAlign: 'center'}}>
                    <Stack sx={{mb: 2}}>
                        <Logo/>
                    </Stack>
                    <Box>
                        <Typography sx={{fontSize: "100px", fontWeight: "bold"}}>404</Typography>
                        <Typography variant="h3" paragraph>
                            Not Found!
                        </Typography>
                    </Box>

                    <Link component={RouterLink} to="/">
                        <Button size="large">
                            Back
                        </Button>
                    </Link>
                </Box>
            </Container>
        </RootStyle>
    );
}
