import {Box, Stack, Typography} from "@mui/material";
import AppTagList from "../../components/AppTagList";
import {MdAdd} from "react-icons/md";
import React from "react";
import {useSelector} from "react-redux";
import AppPaginationTags from "../../components/AppPaginationTags";

interface AppTags {
    tagList: any
    handlerSetSelectTag: (value: any) => void,
    handlerChangePage?: (value: any) => void,
    pages: any
}

const AppTags: React.FC<AppTags> = ({tagList, handlerSetSelectTag, handlerChangePage, pages}) => {
    const state: any = useSelector(state => state)
    return (
        <React.Fragment>

            <Box className="tag-box" sx={{
                "& .MuiBox-root": {padding: "26px 20px!important"}, padding: "10px"
            }}>
                {
                    state.tags && state.tags.length < 10 ?
                        <React.Fragment>
                            <Box>
                                {tagList?.map((item: any) => (
                                    <AppTagList key={item?.id} label={item?.title} item={item} onClick={handlerSetSelectTag}
                                                icon={<MdAdd/>} className="tag"/>
                                ))}
                            </Box>
                            <Stack className="tag-pagination" sx={{padding: 0}}>
                                {
                                    <AppPaginationTags data={pages} onPageChange={handlerChangePage}/>
                                }
                            </Stack>
                        </React.Fragment>
                    :
                    <Typography sx={{fontSize: "15px", color: "#213555" , textAlign:"center"}}>
                        The number of selected tags is more than 10
                    </Typography>
                }
            </Box>
        </React.Fragment>
    )
}

export default React.memo(AppTags)
