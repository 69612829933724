import {call, put, select, takeLatest} from "redux-saga/effects";
import {RELATION_LIST, SET_RELATION_LIST} from "../actions";
import { AxiosResponse } from 'axios';
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {PayloadAction} from "@reduxjs/toolkit";
import {
    ACCEPT_REQUEST,
    FETCH_PENDING_LIST, FETCH_SUGGESTED_RELATION_LIST,
    FETCH_USER_LIST,
    FETCH_USER_POST_LIST, LIKE_USER_POST, SEND_REQUEST, SET_CURRENT_USER,
    SET_PENDING_LIST, SET_SUGGESTED_RELATION_LIST,
    SET_USER_LIST,
    SET_USER_POST_LIST, SWITCH_CLOSE_FRIEND,
    UNFOLLOW_USER
} from "./action";
import {AppSwal} from "../../global/functions";
import {RootState} from "../types";
import {SHOW_POST_LIST, UPDATE_CURRENT_PAGE} from "../posts/action";

export function* GetRelationList() {
    try {
        const response: AxiosResponse = yield call(GET, ApiRouts?.RELATION_LIST, {});
        if (response && response.data) {
            // Dispatch action to set relation list
            yield put({
                type: SET_RELATION_LIST,
                data: response.data.data,
            });
        }
    } catch (error) {
        console.error("Error fetching relation list:", error);
        // Handle error if needed
    }
}
export function* SwitchCloseFriend(action : PayloadAction<{user:string}>) {
    try {
        const response: AxiosResponse = yield call(POST, ApiRouts?.SWITCH_CLOSE_FRIEND, {user:action?.payload?.user});
        if (response && response.data) {
            // Dispatch action to set relation list
            yield call(GetRelationList)
        }
    } catch (error) {
        console.error("Error fetching relation list:", error);
        // Handle error if needed
    }
}

export function* FetchUserList(action : PayloadAction<{user:string}>){
    try{
        const response :AxiosResponse = yield call(POST , <string>ApiRouts?.SHOW_OTHERS_PROFILE, {user:action?.payload?.user})
        if(response){
            yield put({
                type:SET_USER_LIST,
                data:response.data.data
            })
            // yield call(FetchUserPostList)
        }
    }catch(error){
        console.log(error)
    }
}

export function* UnfollowUser(action : PayloadAction<{user:string}>){
    try{
        const response :AxiosResponse = yield call(POST , <string>ApiRouts?.UNFOLLOW, action?.payload)
        if(response){
            yield call(GetRelationList)
        }
    }catch(error){
        console.log(error)
    }
}

export function* FetchUserPostList(action: PayloadAction<{ user:string}>): Generator<any, void, any> {
    try{
        const response :AxiosResponse = yield call(POST , <string>ApiRouts?.POST_LIST, {"user":action?.payload?.user})
        if (response) {
            yield put({
                type:SET_USER_POST_LIST,
                data:response.data.data
            })
        }
    }catch(error){
        console.log(error)
    }
}

export function* FetchPendingList(){
    try{
        const response :AxiosResponse = yield call(GET , <string>ApiRouts?.PENDING_LIST, {})
        if(response){
            yield put({
                type:SET_PENDING_LIST,
                data:response.data.data
            })
        }
    }catch(error){
        console.log(error)
    }
}

export function* AcceptRequest(action : PayloadAction<{user:string}>){
    try{
        const response :AxiosResponse = yield call(POST , <string>ApiRouts?.ACCEPT_REQUEST, {user_id:action?.payload?.user})
        if(response){
            yield call(FetchPendingList)
            yield call(GetRelationList)
            AppSwal({value: response?.data?.message, type: "success"})
        }
    }catch(error){
        console.log(error)
    }
}

export function* FetchSuggestedRelationList(){
    try{
        const response :AxiosResponse = yield call(GET , <string>ApiRouts?.SUGGESTED_RELATION, {})
        if(response){
            yield put({
                type:SET_SUGGESTED_RELATION_LIST,
                data:response.data.data
            })
        }
    }catch(error){
        console.log(error)
    }
}

export function* SendRequest(action : PayloadAction<{uuid:string}>){
    try{
        const response :AxiosResponse = yield call(POST , <string>ApiRouts?.FOLLOW_REQUEST, {user:action?.payload?.uuid})
        if(response){
            yield call(FetchSuggestedRelationList)
            AppSwal({value: response?.data?.message, type: "success"})
        }
    }catch(error){
        console.log(error)
    }
}


function* relationSaga() {
    yield takeLatest(RELATION_LIST, GetRelationList);
    yield takeLatest(FETCH_USER_LIST, FetchUserList);
    yield takeLatest(UNFOLLOW_USER, UnfollowUser);
    yield takeLatest(FETCH_USER_POST_LIST, FetchUserPostList);
    yield takeLatest(FETCH_PENDING_LIST, FetchPendingList);
    yield takeLatest(ACCEPT_REQUEST, AcceptRequest);
    yield takeLatest(FETCH_SUGGESTED_RELATION_LIST, FetchSuggestedRelationList);
    yield takeLatest(SEND_REQUEST, SendRequest);
    yield takeLatest(SWITCH_CLOSE_FRIEND, SwitchCloseFriend);
}

export default relationSaga;
