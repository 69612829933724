import React, {useEffect, useState} from 'react';
import {RouterProvider} from "react-router-dom";
import {router} from "./routes";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './pages/app.css';
import './style.css';
import './pages/responsive.css';
import {Provider, useSelector} from "react-redux";
import configureStore from "./redux/store";
import AuthProvider from "./provider/AuthProvider";
import NotificationProvider from "./provider/NotificationProvider";
import Loader from "./components/Loader/Loader";
import {createTheme, ThemeProvider} from "@mui/material";
import "./assets/fonts/style.css";

const theme = createTheme({
    typography: {
        fontFamily: [
            '"Segoe UI Regular"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

function App() {

    return (
        <React.Fragment>
            <Provider store={configureStore}>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <NotificationProvider>
                            <RouterProvider router={router}/>
                            <ToastContainer/>
                        </NotificationProvider>
                    </AuthProvider>
                </ThemeProvider>
            </Provider>

        </React.Fragment>
    );
}

export default App;
