import React from "react";
import {Button} from "@mui/material";

interface AppButtonProps {
    onClick?: (value: string) => void;
    value: string;
    color?: 'blue' | 'black' | 'gray';
    type?: "submit" | "button";
    disabled?: boolean;
    className?:string
}

const AppButton: React.FC<AppButtonProps> = ({
                                                 onClick,
                                                 value,
                                                 color = "blue",
                                                 type = "submit",
                                                 disabled,
                                className,
                                             }) => {
    const handleClick = (event: any) => {
        if (onClick) onClick(event.target.value);
    };
    return (
        <Button onClick={handleClick}
                sx={{
                    background: color === "blue" ? "#213555" : color === "gray" ? "#272727" : "#000",
                    color: "#fff",
                    borderRadius: "26px",
                    padding: "11px",
                    textTransform:"none",
                    fontWeight:"bold"
                }}
                className={className}
                fullWidth={true}
                type={type}
                variant="contained"
                disabled={disabled}
        >
            {value}
        </Button>
    );
};
export default React.memo(AppButton);