import {SET_COMMENT} from "./action";

interface SetInitialized {
    type: string;
    data: any;
    comment_type:any
}

type ActionType = SetInitialized;

const initializedState = {
    data:null,
    comment_type:null
};

const reducer = (state = initializedState, action: ActionType) => {
    const { data , comment_type} = action;
    switch (action?.type) {
        case SET_COMMENT:
            return action;
        default:
            return state;
    }
};

export default reducer;
