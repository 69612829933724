import {DO_REMOVE_PARTICIPANT, SET_CURRENT_CHAT} from "./action";

interface SetInitialized {
    type: string;
    data: any
}

type ActionType = SetInitialized;

const initializedState = null

const reducer = (state = initializedState, action: ActionType) => {
    const {data} = action;
    switch (action?.type) {
        case SET_CURRENT_CHAT:
            return data;
        default:
            return state;
    }
};

export default reducer;
