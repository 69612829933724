import axios from "../boot/axios";
import {useSelector} from "react-redux";

export async function GET(url: string, params: object) {
    return axios.get(url, {params})
}

export async function POST(url: string, data: any) {
    return axios.post(url, data)
}

export async function PUT(request: Request) {
}

export async function DELETE(request: Request) {
}

export async function PATCH(request: Request) {
}
