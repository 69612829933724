import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const config = () => {
    const store: any = configureStore({
        reducer: {
            ...reducers,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({thunk: false}).concat(sagaMiddleware),
    });

    sagaMiddleware.run(sagas);

    //@ts-ignore
    if (module.hot) {
        //@ts-ignore
        module.hot.accept("./reducers", () => {
            const nextRootReducer = require("./reducers");
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

const store = config();
export default store;

