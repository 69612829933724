import {createBrowserRouter, Navigate} from 'react-router-dom';
import {Suspense, lazy, useEffect} from "react";
import GuestGuard from "../guards/GuestGuard";
import Layout from "../pages/app/layout";
import AuthGuard from "../guards/AuthGuard";
import Loader from "../components/Loader/Loader";
import createSagaMiddleware from "redux-saga";
import {PATH_AUTH, PATH_DASHBOARD} from "./paths";
import Page404 from "../pages/404";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {ERROR} from "../redux/actions";
import {UPDATE_CURRENT_PAGE} from "../redux/posts/action";

const sagaMiddleware = createSagaMiddleware();
const Loadable = (Component: any) => (props: any) => {
    const state: any = useSelector(state => state)
    useEffect(() => {
        if(state?.error !== null){
            store.dispatch({
                type: ERROR,
                data: null
            })
        }
        store.dispatch({
            type:"SET_COMMENT",
            data:null
        })

    }, []);
    return (
        <Suspense fallback={<Loader/>}>
            <Component {...props} />
        </Suspense>
    );
};

/**
 * Dashboard Route
 * @type {function(*)}
 */

const Profile = Loadable(lazy(() => import('../pages/app/profile')));
const Dashboard = Loadable(lazy(() => import('../pages/app/dashboard')));
const CreateProfile = Loadable(lazy(() => import('../pages/auth/create-profile')));
const SelectTag = Loadable(lazy(() => import('../pages/app/tags/select-tag')));
const EnterTag = Loadable(lazy(() => import('../pages/app/tags/enter-tag')));
const Users = Loadable(lazy(() => import('../pages/app/users')));
const Bookmark = Loadable(lazy(() => import('../pages/app/bookmark')));
const Chat = Loadable(lazy(() => import('../pages/app/chat')));
const Explore = Loadable(lazy(() => import('../pages/app/explore')));


/**
 *Auth Route
 * @type {function(*)}
 */

const Login = Loadable(lazy(() => import('../pages/auth/sign-in')));
const Register = Loadable(lazy(() => import('../pages/auth/sign-up')));
const ForgetPassword = Loadable(lazy(() => import('../pages/auth/forget-password')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/reset-password')));
const EmailVerify = Loadable(lazy(() => import('../pages/auth/email-verify')));
const GoogleCallback = Loadable(lazy(() => import('../pages/auth/google/callback')));
const FacebookCallback = Loadable(lazy(() => import('../pages/auth/facebook/callback')));
const WaitingToVerifyEmail = Loadable(lazy(() => import('../pages/auth/waiting-to-verify-email')));

/**
 * Loadable Function
 * @param Component
 * @returns {function(*)}
 * @constructor
 */

/***
 * Router Function
 * @returns {React.ReactElement}
 * @constructor
 */
export const router = createBrowserRouter([
    /// AUTH ROUTES
    {
        path: 'auth',

        children: [
            {path: 'login', element: <GuestGuard><Login/></GuestGuard>},
            {path: 'register', element: <GuestGuard><Register/></GuestGuard>},
            {path: 'forgot-password', element: <GuestGuard><ForgetPassword/></GuestGuard>},
            {path: 'reset-password/:id', element: <GuestGuard><ResetPassword/></GuestGuard>},
            {path: 'google/callback', element: <GuestGuard><GoogleCallback/></GuestGuard>},
            {path: 'facebook/callback', element: <GuestGuard><FacebookCallback/></GuestGuard>},
            {path: 'email-verify', element: <EmailVerify/>},
            {path: 'enter-tag', element: <EnterTag/>},
        ],
    },
    /// DASHBOARD ROUTES
    {
        path: 'app',
        children: [
            {element: <Navigate to={PATH_DASHBOARD.profile} replace />, index: true },
            {path: 'profile', element: <AuthGuard><Profile/></AuthGuard>},
            {path: 'dashboard', element: <AuthGuard><Dashboard/></AuthGuard>},
            {path: 'create-profile', element: <AuthGuard><CreateProfile/></AuthGuard>},
            {path: 'select-tag', element: <AuthGuard><SelectTag/></AuthGuard>},
            {path: 'enter-tag', element: <AuthGuard><EnterTag/></AuthGuard>},
            {path: 'users', element: <AuthGuard><Users/></AuthGuard>},
            {path: 'bookmark', element: <AuthGuard><Bookmark/></AuthGuard>},
            {path: 'chat', element: <AuthGuard><Chat/></AuthGuard>},
            {path: 'explore', element: <AuthGuard><Explore/></AuthGuard>},
        ],
    },

    {
        children: [
            {element: <Navigate to={PATH_AUTH.login} replace/>, index: true},
            {path: '*', element: <Page404/>}
        ],
    }

]);
/*export default function Router() {
    return useRoutes(routes)
}*/


sagaMiddleware.setContext({
    router,
});

